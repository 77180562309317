import Parse from 'parse'
import { user as userTools, money } from '@sellpy/commons'
import memoize from 'lodash/memoize'
import { getAmount } from '../../client/checkout/tools'
import { DataLayer } from './dataLayer'

export const getTileTrackFunction = memoize(
  (data) => () => analyticsSliderTileClicked(data),
  ({ sliderId, tileId, index, totalCount }) => `${sliderId}_${tileId}_${index}_${totalCount}`
)

export function analyticsEnableAnalytics() {
  DataLayer.trackPermissionAnalytics({ permissionValue: true })
}

export function analyticsDisableAnalytics() {
  DataLayer.trackPermissionAnalytics({ permissionValue: false })
}

export function analyticsEnableExternalMarketing() {
  DataLayer.trackPermissionExternalMarketing({ permissionValue: true })
}

export function analyticsDisableExternalMarketing() {
  DataLayer.trackPermissionExternalMarketing({ permissionValue: false })
}

export function analyticsSetUserId() {
  if (Parse.User.current() && userTools.userIsFull(Parse.User.current())) {
    DataLayer.trackSetUserId({ id: Parse.User.current().id })
  }
}

export function analyticsUnsetUserId() {
  DataLayer.trackUnsetUserId()
}

export const analyticsSetUserEmail = ({ email }) => {
  email && DataLayer.trackSetUserEmail({ email })
}

export function analyticsUnsetUserEmail() {
  DataLayer.trackUnsetUserEmail()
}

export const analyticsSetUserPhone = ({ phone }) => {
  phone && DataLayer.trackSetUserPhone({ phone })
}

export function analyticsUnsetUserPhone() {
  DataLayer.trackUnsetUserPhone()
}

export const analyticsHitsViewed = ({
  algoliaIndex,
  queryId,
  algoliaSearchHits,
  abTestID,
  abTestVariantID
}) =>
  DataLayer.trackHitsViewed({
    algoliaIndex,
    queryId,
    algoliaSearchHits,
    abTestID,
    abTestVariantID
  })

export const analyticsCheckoutAddPromoCode = (payload) =>
  DataLayer.trackCheckoutAddPromoCode(payload)

export const analyticsCheckoutViewAddShipping = (payload) =>
  DataLayer.trackCheckoutViewAddShipping(payload)

export const analyticsCheckoutAddShippingInfo = (payload) =>
  DataLayer.trackCheckoutAddShippingInfo(payload)

export const analyticsCheckoutViewPaymentInfo = (payload) =>
  DataLayer.trackCheckoutViewPaymentInfo(payload)

export const analyticsCheckoutAddPaymentInfo = (payload) =>
  DataLayer.trackCheckoutAddPaymentInfo(payload)

export const analyticsViewAddEmail = (payload) => DataLayer.trackCheckoutViewAddEmail(payload)

export const analyticsDiscoverMore = (payload) => DataLayer.trackDiscoverMoreClicks(payload)

export const analyticsAddEmailInfo = (payload) => DataLayer.trackCheckoutAddEmailInfo(payload)

export const analyticsCheckoutViewAddAddress = (payload) =>
  DataLayer.trackCheckoutViewAddAddress(payload)

export const analyticsCheckoutAddShippingAddress = (payload) =>
  DataLayer.trackCheckoutAddShippingAddress(payload)

export const analyticsViewReviewOrder = (payload) => DataLayer.trackCheckoutViewReviewOrder(payload)

export const analyticsReviewOrder = (payload) => DataLayer.trackCheckoutReviewOrder(payload)

export const analyticsBeginCheckoutV2 = (payload) => DataLayer.trackBeginCheckoutV2(payload)

export function analyticsGiftCardPurchase({ totalPayment, paymentId }) {
  const amount = totalPayment.amount
  const currency = totalPayment.currency

  DataLayer.trackPurchase({
    purchaseType: 'giftCard',
    paymentId,
    price: amount,
    currency,
    stringValue: amount.toString()
  })
}

export function analyticsLoyaltyPeriodPurchase({ payment, paymentId }) {
  const amount = payment.amount
  const currency = payment.currency

  DataLayer.trackPurchase({
    purchaseType: 'loyaltyProgram',
    paymentId,
    price: amount,
    currency,
    stringValue: amount.toString()
  })
}

export function analyticsPurchase({
  totalPayment,
  purchaseType,
  marketOrderCount,
  itemIds,
  orderId,
  paymentId,
  freightPayment,
  items
}) {
  const amount = totalPayment.amount
  const currency = totalPayment.currency

  DataLayer.trackPurchase({
    paymentId,
    price: amount,
    shipping: freightPayment.amount,
    currency,
    marketOrderCount: String(marketOrderCount),
    items:
      items ||
      itemIds.map((id) => ({
        id: id
      })),
    purchaseType,
    stringValue: amount.toString(),
    itemIds,
    orderId
  })

  return () => {
    intercomSend(purchaseType, {
      value: amount,
      currency: currency
    })
  }
}
export const analyticsPurchaseV2 = (payload) => DataLayer.trackPurchaseV2(payload)

export const analyticsGiftCardAddedToCart = ({ amount, currency }) => {
  DataLayer.trackGiftCardAddToCart({ amount, currency })
}

export const analyticsGiftCardRemovedFromCart = ({ amount, currency }) => {
  DataLayer.trackGiftCardRemoveFromCart({ amount, currency })
}

export function analyticsMenuNavigation({ label, action }) {
  DataLayer.trackMenuNavigation({ label, action })
}

export function analyticsHomeTabNavigation({ tab }) {
  DataLayer.trackHomeTabNavigation({ tab })
}

export function analyticsMapDrawer({ action, pickupAvailable }) {
  DataLayer.trackMapDrawer({ action, pickupAvailable })
}

export function analyticsOrderPickupButton({ location }) {
  DataLayer.trackOrderPickupButton({ location })
}

export function analyticsDropoffButton() {
  DataLayer.trackDropoffButton()
}

export function analyticsCommencePreparedItemSale() {
  DataLayer.trackCommencePreparedItemSale()
}

export function analyticsTrackExtendPricingPeriodModalOpen() {
  DataLayer.trackExtendPricingPeriodModalOpen()
}

export function analyticsSetNewPricingModalOpen() {
  DataLayer.trackSetNewPricingModalOpen()
}

export function analyticsBagOrderButton({ location }) {
  DataLayer.trackBagOrderButton({ location })
}

export function analyticsContainerOrderHistoryButton({ location }) {
  DataLayer.trackContainerOrderHistoryButton({ location })
}

export function analyticsPlpPromotionalBanner({ action, name }) {
  DataLayer.trackPlpPromotionalTile({ action, name })
}

export const analyticsItemPricingChargeInitiated = ({ pricing: { amount }, itemId }) => {
  DataLayer.trackBeginCheckout({ items: [{ name: 'item_pricing', id: itemId, price: amount }] })
}

export const analyticsItemPricingChargeCompleted = ({ pricing: { amount }, itemId }) => {
  DataLayer.trackPurchase({ items: [{ name: 'item_pricing', id: itemId, price: amount }] })
}

export const analyticsGiftCardRedeemed = ({ giftCardId }) => {
  DataLayer.trackGiftCardRedeemed({ id: giftCardId })
}

export function analyticsReevaluationRequestStarted(itemId) {
  DataLayer.trackReevaluationRequestStart({ itemId })
}

export function analyticsReferralLinkCopied(referrer) {
  DataLayer.trackReferralLinkCopied({ referrer })
}

export function analyticsReevaluationRequestCompleted({ itemId, motivation, returnIfRejected }) {
  DataLayer.trackReevaluationRequestComplete({ itemId, motivation })
  const eventType = 'ReevaluationRequestCompleted'
  intercomSend(eventType, { itemId, motivation, returnIfRejected })
}

export function analyticsCheckout({ items }) {
  DataLayer.trackBeginCheckout({ items })
  intercomSend('InitiateCheckout')
}

export function analyticsSendBackRequestCheckoutInitiated(payment, properties = {}) {
  const amounts = [
    payment.get('adyenAmount') && money.toBaseUnit(payment.get('adyenAmount')),
    payment.get('creditAmount')
  ].filter(Boolean)
  DataLayer.trackBeginCheckout({
    items: [{ name: 'send_back_request', id: properties.itemId, price: money.add(...amounts) }]
  })
}

export function analyticsSendBackRequestCheckoutConfirmed(payment) {
  const amount = getAmount(payment)
  DataLayer.trackPurchase({
    paymentId: payment.id,
    items: [{ name: 'send_back_request', price: amount }]
  })
}

export const getPSPs = (payment) =>
  [
    payment && payment.get('adyenAmount') && 'Adyen',
    (!payment || payment.get('creditAmount')) && 'Sellpy'
  ].filter(Boolean)

export function analyticsViewContent({ item, priceObject }) {
  DataLayer.trackItemView(item)
}

export function analyticsRemoveFromCart(items, checkoutType) {
  DataLayer.trackRemoveFromCart({ checkoutType, items })
}

export function analyticsCart(items, checkoutType) {
  DataLayer.trackAddToCart({ checkoutType, items })
  const user = Parse.User.current()
  trackEventAllProviders('AddToCart', {
    anonymous: !user || userTools.userIsAnonymous(user)
  })
}

export function analyticsAddToWishlist(items) {
  DataLayer.trackAddToWishList({ items })
  const eventType = 'AddToWishlist'
  intercomSend(eventType)
}

/**
 * Tracking for the autocomplete suggestions and serachbox
 *
 * @param {string} searchTerm The query that the customer has wrote
 * @param {string} searchSource There are four type of search facets / indexs depending which recommendation the customer clicked on. Either category, brand, suggestedText (querysuggestions), text (plain search).
 */
export function analyticsSearch({ searchTerm, searchSource }) {
  DataLayer.trackSearch({ searchTerm, searchSource })
}

export function analyticsAddTrackedSearch({ trackedSearch }) {
  DataLayer.trackAddTrackedSearch({
    name: trackedSearch.name || trackedSearch.attributes?.name || ''
  })
}

export function analyticsLogin(provider) {
  DataLayer.trackLogin({ method: provider })
  intercomSend('Login')
}

export function analyticsSignUp(provider) {
  DataLayer.trackSignup({ method: provider })
  intercomSend('SignUp')
}

export function analyticsPickUpOrder(nrOfBags, page) {
  DataLayer.trackPickupOrder({
    nrOfBags,
    page,
    title: 'Order Pickup'
  })
  intercomSend('BagPickUp')
}

export function analyticsBagRating(value, comment, improvementArea) {
  DataLayer.trackBagRating({ score: value, comment, improvementArea })
  // must have unique types, since one cannot filter on metadata in intercom
  intercomSend('BagRating' + value, { comment, improvementArea })
}

export function analyticsClaimCompleted({ itemId, paymentId }) {
  DataLayer.trackRefund({
    itemId,
    paymentId
  })
  intercomSend('ClaimCompleted', { itemId })
}

export const analyticsFakeDoorPremiumReturnFreight = ({ userId }) =>
  DataLayer.trackFakeDoorClick({ userId, type: 'premiumReturn' })

export const analyticsEditedItem = (itemId) => intercomSend('EditedItem', { itemId })

export const analyticsItemChangeRequested = (itemId) =>
  intercomSend('ItemChangeRequested', { itemId })

export const analyticsPricedItem = (itemId, value) =>
  intercomSend('ItemCustomerPriced', { itemId, value })

export const analyticsOrderRating = (orderId, score, improvementArea) =>
  intercomSend(`OrderRating-${score}`, { orderId, improvementArea })

export const analyticsSliderItemClicked = (data) => {
  DataLayer.trackClickSliderItem(data)
}

export const analyticsAccordionClicked = (accordionId) => {
  DataLayer.trackAccordionClick({ accordionId })
}

export const analyticsSliderTileClicked = ({ sliderId, tileId, ...data }) => {
  DataLayer.trackClickTile({
    baseCategory: sliderId,
    tileName: tileId,
    ...data
  })
}

export const analyticsP2pStartSaleInitiated = (data) => {
  DataLayer.trackP2pSaleInitiated({ itemId: data.itemId, value: data.p2pListings })
}

export const analyticsP2pStartSaleCompleted = (data) => {
  DataLayer.trackP2pSaleCompleted({ itemId: data.itemId, value: data.p2pListings })
}

export const trackClickHeroBanner = ({ segment, campaign, position, target, ...data }) => {
  DataLayer.trackPromotionClick({
    promotionName: campaign,
    segment: segment,
    location: 'herobanner',
    position,
    target,
    ...data
  })
}

export const trackClickAsymmetricBanner = ({ segment, campaign, position }) => {
  DataLayer.trackPromotionClick({
    promotionName: campaign,
    segment: segment,
    location: 'asymmetricBanner',
    position
  })
}

export const trackClickStoreBanner = ({ segment, campaign, position }) => {
  DataLayer.trackPromotionClick({
    promotionName: campaign,
    segment: segment,
    location: 'storeBanner',
    position
  })
}

const trackEventAllProviders = (eventType, extra) => {
  intercomSend(eventType)
}

const intercomSend = (type, fields) => {
  setTimeout(() => window.Intercom && window.Intercom('trackEvent', type, fields), 3000)
}

export const analyticsEstimateSellabilityCalculation = (brand, type) => {
  DataLayer.trackEstimateSellabilityCalculation({ brand, type })
}

export const analyticsItemPricingOpenModal = () => {
  DataLayer.trackItemPricingOpenModal()
}

export const analyticsSellpyFirstSignupInCart = () => {
  DataLayer.trackSellpyFirstCheckoutSignup()
}

export const analyticsItemPricingSelectedLength = ({ days, daysRemaining, price }) => {
  DataLayer.trackItemPricingSelectedLength({ days, daysRemaining, price })
}

export const analyticsCreateSearchProfile = () => {
  DataLayer.trackCreateSearchProfile()
}

export const analyticsCompleteSearchProfile = () => {
  DataLayer.trackCompleteSearchProfile()
}

export const analyticsApplySearchProfile = () => {
  DataLayer.trackApplySearchProfile()
}

export const analyticsSearchPromotionalFooterClick = (type) => {
  DataLayer.trackPromotionClick({
    promotionName: `Promotional footer in search: ${type}`,
    segment: null,
    location: 'footerBanner',
    position: null
  })
}

export const analyticsSuggestedBrandsBannerClick = (brand) => {
  DataLayer.trackSimilarBrandsBannerClick({ brand })
}

export const analyticsFulfillmentP2p = ({ action }) => {
  DataLayer.trackFulfillmentP2p({ action })
}

export const analyticsShareMyStore = ({ pathname }) => {
  DataLayer.trackShareMyStore({ pathname })
}

export const analyticsApproveAll = ({ action }) => {
  DataLayer.trackApproveAll({ action })
}

export const analyticsRateSaleContactCHTClick = ({ type, improvementArea, score, comment }) => {
  DataLayer.trackRateSaleContactCHTClick({ type, improvementArea, score, comment })
}

export function analyticsShowLoggedOutFavoriteModal() {
  DataLayer.trackViewFavoriteModal()
}

export const analyticsItemEditSubmit = ({
  itemId,
  dirtyFields,
  originalMetadata,
  editedMetadata
}) => {
  const changes = Object.entries(dirtyFields).reduce(
    (recordedChanges, [field]) => ({
      ...recordedChanges,
      [`${field}_change`]: JSON.stringify({
        from: originalMetadata[field],
        to: editedMetadata[field]
      })
    }),
    {}
  )
  DataLayer.trackItemEditSubmit({ itemId, changes })
}
export const analyticsViewSustainability = () => {
  DataLayer.trackViewsSustainability()
}
