import styled from 'styled-components'
import { Button, H3 } from '@sellpy/design-system-react-web'
import { mediaQueries, largerThanLandingPageMaxWidth } from '../../uiComponents/mediaQueries'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.blue.shade10};
  padding: 32px 16px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(12, 1fr);

  ${mediaQueries.largerThanTablet} {
    padding: 32px;
  }
  ${largerThanLandingPageMaxWidth} {
    margin-inline: 32px;
  }
`

export const StyledButton = styled(Button)`
  span {
    white-space: nowrap;
  }
  ${mediaQueries.largerThanTablet} {
    margin-top: 18px; /* aligns the button with the singlelineinput while ignoring its label */
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 3 / 8;
  ${mediaQueries.tablet} {
    grid-column: 1 / 7;
  }
  ${mediaQueries.phone} {
    grid-column: 1 / 13;
  }
`

export const Header = styled(H3)`
  ${mediaQueries.largerThanPhone} {
    margin-bottom: 0.5rem;
  }
  margin-bottom: 1rem;
`

export const StyledForm = styled.form`
  grid-column: 8 / 13;
  ${mediaQueries.tablet} {
    grid-column: 7 / 13;
  }
  ${mediaQueries.phone} {
    grid-column: 1 / 13;
  }
`

export const FieldWithButton = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: flex-start;
  ${mediaQueries.tablet} {
    flex-direction: column;
    align-items: stretch;
  }
`

export const StyledInputContainer = styled.div`
  flex-grow: 1;
`
