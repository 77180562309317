import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { marketplace, region } from '@sellpy/commons'
import { Button, P, SimpleModal, A } from '@sellpy/design-system-react-web'
import { createPortal } from 'react-dom'
import { useInferredLocation } from '../../common/hooks/useInferredLocation'
import useResponsive from '../hooks/useResponsive.js'
import constants from './constants.json'

const StyledSimpleModal = styled(SimpleModal)`
  z-index: var(--redirect-modal-z-index);
  & > :first-child {
    height: auto;
  }
`

const CustomButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  border-top: 1px solid rgb(238, 240, 241);
  margin: 0 -16px;
  padding: 16px 32px 0;
  gap: 32px;
`

const REDIRECT_DECLINED = 'REDIRECT_DECLINED'

const RedirectSuggestion = () => {
  const { isMobile } = useResponsive()
  const inferredLocation = useInferredLocation()
  const [redirectDeclined, setRedirectDeclined] = useState(
    window.sessionStorage.getItem(REDIRECT_DECLINED)
  )
  const { t } = useTranslation()
  const onRedirectDeclined = () => {
    window.sessionStorage.setItem(REDIRECT_DECLINED, true)
    setRedirectDeclined(true)
  }

  const offerRedirect =
    !redirectDeclined &&
    marketplace.BASE_URLS[inferredLocation] &&
    inferredLocation !== process.env.REGION

  const isPrerender = () => {
    const userAgent = navigator.userAgent
    const crawlerUserAgent = 'Prerender'
    return userAgent.toLowerCase().indexOf(crawlerUserAgent.toLowerCase()) !== -1
  }

  if (isPrerender()) {
    return null
  }
  if (!inferredLocation) return null

  const { country, localized, redirectSuggestion, redirectActivated } = constants

  const textLabel = t(redirectSuggestion.text[marketplace.LOCALE[inferredLocation]], {
    country: country[inferredLocation],
    localized: localized[inferredLocation]
  })
  const buttonLabel = t(redirectSuggestion.button[marketplace.LOCALE[inferredLocation]], {
    country: country[inferredLocation],
    localized: localized[inferredLocation]
  })
  const headline = t(redirectSuggestion.headline[marketplace.LOCALE[inferredLocation]], {
    country: country[inferredLocation],
    localized: localized[inferredLocation]
  })
  const cancelButton = t(redirectSuggestion.cancelButton[marketplace.LOCALE[inferredLocation]], {
    country: country[inferredLocation],
    localized: localized[inferredLocation]
  })

  return createPortal(
    <StyledSimpleModal
      isOpen={redirectActivated[inferredLocation] && offerRedirect && !redirectDeclined}
      onClose={() => {
        onRedirectDeclined()
      }}
      headline={headline}
      showCancelButton={true}
    >
      <P design='body1' style={{ marginBottom: '32px' }}>
        {textLabel}
      </P>
      <CustomButtonsWrapper>
        <Button
          size={isMobile ? 'small' : 'medium'}
          label={cancelButton}
          variant={'text'}
          onClick={() => onRedirectDeclined()}
          type={'button'}
          dataTestid='cancel-button'
        />
        <A
          hrefLang={region.languageLocalisationCode(inferredLocation)}
          href={marketplace.BASE_URLS[inferredLocation]}
          data-testid='redirect-button'
        >
          <Button size={isMobile ? 'small' : 'medium'} label={buttonLabel} type={'button'} />
        </A>
      </CustomButtonsWrapper>
    </StyledSimpleModal>,
    document.querySelector('#portal-root')
  )
}

export default RedirectSuggestion
