fragment ClaimInfoFragment on ItemClaim {
  objectId
  refundData
  reason
  paidAt
  createdAt
  images {
    ... on Element {
      value
    }
  }
}

fragment ClaimInfoItemFragment on Item {
  objectId
  metadata
  metadata_de
  metadata_en
  metadata_nl
  metadata_da
  metadata_pl
  metadata_fi
  metadata_fr
  metadata_cs
}
