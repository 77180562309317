import React, { Suspense } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { PasswordlessHook } from '../login/PasswordlessHook.jsx'
import { Toasts } from '../uiComponents/index.js'
import EditFavoriteModal from '../myShopping/components/EditFavoriteModal.jsx'
import LoggedOutFavoriteModal from '../components/LoggedOutFavoriteModal.jsx'
import { GlobalMetaTagPlacer } from '../metadata/GlobalMetaTagPlacer.jsx'
import UserWrapper from '../routing/UserWrapper.jsx'
import Layout from '../routing/Layout.jsx'
import PersistantFooterStack from '../routing/PersistantFooter.jsx'
import RedirectSuggestion from '../region/RedirectSuggestion.jsx'
import { IntercomFAB } from './IntercomFAB.jsx'

console.log('You are running version:', process.env.COMMIT_HASH)

export const App = () => (
  <>
    <ScrollRestoration />
    <GlobalMetaTagPlacer />
    <PasswordlessHook>
      <UserWrapper>
        <Layout>
          {/* <Suspense> because we are lazy-loading the routes that are outputted here. */}
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout>
      </UserWrapper>
      <PersistantFooterStack />
      <Toasts />
      <IntercomFAB />
      <EditFavoriteModal />
      <LoggedOutFavoriteModal />
      <RedirectSuggestion />
    </PasswordlessHook>
  </>
)

export default App
