import React, { memo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import isMobile from 'ismobilejs'
import { Icon, SLogo, ICONS, Button, H2, P, A } from '@sellpy/design-system-react-web'
import { DateTime } from 'luxon'
import { matchRoutes, useLocation } from 'react-router-dom'
import { SIZES } from '../../../uiComponents'

const LOCAL_STORAGE_KEY = 'appDownloadBannerDismissedAt'

const { android, apple, other } = isMobile()

/**
 * Check if the user is using an iOS device and is using Safari
 * This is tricky to do so we just use a best effort approach
 */
const isIosSafari = () => {
  const { userAgent } = window.navigator
  return (
    apple.device &&
    !other.device && // Other browsers according to ismobilejs
    // user agents: https://www.whatismybrowser.com/guides/the-latest-user-agent/ios
    userAgent.includes('Version/') && // only safari should include this
    !userAgent.includes('CriOS/') && // chrome on ios
    !userAgent.includes('FxiOS/') && // firefox on ios
    !userAgent.includes('EdgiOS/') && // edge on ios
    !userAgent.includes('Snapchat/') // snapchat webview
  )
}

const getAppLink = () => {
  if (android.phone) return 'intent:sellpy://#Intent;package=com.sellpy.sellpy;scheme=sellpy://;end'
  if (apple.phone) return `itms-apps://itunes.apple.com/app/id1594599102`
  return null
}

const isMoreThanAMonthAgo = (isoDate) => {
  const givenDate = DateTime.fromISO(isoDate)
  const currentDate = DateTime.utc()
  const differenceInMonths = currentDate.diff(givenDate, 'months').months
  return differenceInMonths > 1
}

const shouldHideAppBanner = (location) => {
  const routesToMatch = [{ path: '/sale/:id', end: true }]
  const matches = matchRoutes(routesToMatch, location)
  return Boolean(matches)
}

const displayBanner = (location) => {
  const dismissedAt = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  const dismissedMoreThanOneMonthAgo = dismissedAt && isMoreThanAMonthAgo(dismissedAt)
  return (
    !isIosSafari() && // iOS Safari has a separate native banner
    getAppLink() &&
    (!dismissedAt || dismissedMoreThanOneMonthAgo) &&
    !shouldHideAppBanner(location)
  )
}

const useBannerVisibility = () => {
  const location = useLocation()
  const show = displayBanner(location)
  const [shouldShow, setShouldShow] = useState(show)

  useEffect(() => {
    setShouldShow(show)
  }, [location])

  const hide = useCallback(() => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, new Date().toISOString())
    setShouldShow(false)
  }, [])
  return { shouldShow, hide }
}

const Wrapper = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.grey.shade8};
  padding: 16px 0px 16px 16px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  overflow: hidden;
  max-width: 100%;
  height: 40px;
`

const Logo = styled(SLogo)`
  height: 40px;
  width: 40px;
`

const Title = styled(H2)`
  color: ${({ theme }) => theme.color.grey.shade1};
`

const SubTitle = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StyledA = styled(A)`
  flex-grow: 0;
`

const CloseButton = styled.button`
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.color.black.default};
  flex-shrink: 0;
`

const AppDownloadBanner = memo(() => {
  const { shouldShow, hide } = useBannerVisibility()
  const { t } = useTranslation('common')
  const appLink = getAppLink()
  if (!shouldShow || !appLink) return null
  return (
    <Wrapper>
      <Logo />
      <TextContainer>
        <Title design='body4' noMargin>
          {t('appDownloadBanner.title')}
        </Title>
        <SubTitle design='body5' noMargin>
          {t('appDownloadBanner.subtitle')}
        </SubTitle>
      </TextContainer>
      <StyledA href={appLink}>
        <Button variant='outlined' size={SIZES.SMALL} label={t('appDownloadBanner.download')} />
      </StyledA>
      <CloseButton aria-label='Close banner' role='button' onClick={hide}>
        <Icon name={ICONS.CLOSE} style={{ fontSize: '14px' }} />
      </CloseButton>
    </Wrapper>
  )
})
AppDownloadBanner.displayName = 'AppDownloadBanner'

export default AppDownloadBanner
