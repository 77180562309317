import { marketplace, searchStateTools } from '@sellpy/commons'
import algoliasearch from 'algoliasearch/lite'
import config from 'config'
import { useInstantSearch } from 'react-instantsearch'
import { locale } from '../region/locale'
import { region } from '../region/region'
import usePrevious from '../../client/hooks/usePrevious'
/** @import { SearchClient, SearchIndex } from 'algoliasearch' */

export const algoliaClients = {
  [marketplace.LOCALE.SE]: algoliasearch(
    config.algolia.applicationIdSV,
    config.algolia.searchKeySV
  ),
  [marketplace.LOCALE.FI]: algoliasearch(
    config.algolia.applicationIdFI,
    config.algolia.searchKeyFI
  ),
  [marketplace.LOCALE.DK]: algoliasearch(
    config.algolia.applicationIdDA,
    config.algolia.searchKeyDA
  ),
  [marketplace.LOCALE.FR]: algoliasearch(
    config.algolia.applicationIdFR,
    config.algolia.searchKeyFR
  ),
  [marketplace.LOCALE.NL]: algoliasearch(
    config.algolia.applicationIdNL,
    config.algolia.searchKeyNL
  ),
  [marketplace.LOCALE.DE]: algoliasearch(
    config.algolia.applicationIdDE,
    config.algolia.searchKeyDE
  ),
  [marketplace.LOCALE.PL]: algoliasearch(
    config.algolia.applicationIdPL,
    config.algolia.searchKeyPL
  ),
  [marketplace.LOCALE.EU]: algoliasearch(
    config.algolia.applicationIdEN,
    config.algolia.searchKeyEN
  ),
  [marketplace.LOCALE.CZ]: algoliasearch(
    config.algolia.applicationIdCS,
    config.algolia.searchKeyCS
  ),
  nonItem: algoliasearch(config.algolia.applicationIdNonItem, config.algolia.searchKeyNonItem)
}

/** @returns {SearchClient} */
export const getAlgoliaClient = () => {
  return algoliaClients[locale]
}

const algoliaIndexes = {
  brand: algoliaClients.nonItem.initIndex(config.algolia.brandIndex),
  itemType_sv: algoliaClients.nonItem.initIndex(config.algolia.typeIndexSV),
  itemType_de: algoliaClients.nonItem.initIndex(config.algolia.typeIndexDE),
  itemType_en: algoliaClients.nonItem.initIndex(config.algolia.typeIndexEN),
  itemType_nl: algoliaClients.nonItem.initIndex(config.algolia.typeIndexNL),
  itemType_da: algoliaClients.nonItem.initIndex(config.algolia.typeIndexDA),
  itemType_pl: algoliaClients.nonItem.initIndex(config.algolia.typeIndexPL),
  itemType_fi: algoliaClients.nonItem.initIndex(config.algolia.typeIndexFI),
  itemType_fr: algoliaClients.nonItem.initIndex(config.algolia.typeIndexFR),
  itemType_cs: algoliaClients.nonItem.initIndex(config.algolia.typeIndexCS),
  marketAutoComplete: algoliaClients.nonItem.initIndex(config.algolia.marketAutocompleteIndex),
  querySuggestions_sv: algoliaClients.sv.initIndex(config.algolia.querySuggestionsSV),
  querySuggestions_de: algoliaClients.de.initIndex(config.algolia.querySuggestionsDE),
  querySuggestions_en: algoliaClients.en.initIndex(config.algolia.querySuggestionsEN),
  querySuggestions_pl: algoliaClients.pl.initIndex(config.algolia.querySuggestionsPL),
  querySuggestions_da: algoliaClients.nl.initIndex(config.algolia.querySuggestionsDA),
  querySuggestions_fi: algoliaClients.fi.initIndex(config.algolia.querySuggestionsFI),
  querySuggestions_nl: algoliaClients.nl.initIndex(config.algolia.querySuggestionsNL),
  querySuggestions_fr: algoliaClients.fr.initIndex(config.algolia.querySuggestionsFR),
  querySuggestions_cs: algoliaClients.cs.initIndex(config.algolia.querySuggestionsCS),
  structuredSearchQuerySuggestions_sv: algoliaClients.sv.initIndex(
    config.algolia.structuredSearchQuerySuggestionsSV
  ),
  structuredSearchQuerySuggestions_de: algoliaClients.de.initIndex(
    config.algolia.structuredSearchQuerySuggestionsDE
  ),
  structuredSearchQuerySuggestions_en: algoliaClients.en.initIndex(
    config.algolia.structuredSearchQuerySuggestionsEN
  ),
  structuredSearchQuerySuggestions_pl: algoliaClients.pl.initIndex(
    config.algolia.structuredSearchQuerySuggestionsPL
  ),
  structuredSearchQuerySuggestions_da: algoliaClients.da.initIndex(
    config.algolia.structuredSearchQuerySuggestionsDA
  ),
  structuredSearchQuerySuggestions_fi: algoliaClients.fi.initIndex(
    config.algolia.structuredSearchQuerySuggestionsFI
  ),
  structuredSearchQuerySuggestions_nl: algoliaClients.nl.initIndex(
    config.algolia.structuredSearchQuerySuggestionsNL
  ),
  structuredSearchQuerySuggestions_fr: algoliaClients.fr.initIndex(
    config.algolia.structuredSearchQuerySuggestionsFR
  ),
  structuredSearchQuerySuggestions_cs: algoliaClients.cs.initIndex(
    config.algolia.structuredSearchQuerySuggestionsCS
  ),
  marketItem_de: algoliaClients.de.initIndex(config.algolia.marketItemDeIndexSaleStartDescending),
  marketItem_se: algoliaClients.sv.initIndex(config.algolia.marketItemSeIndexSaleStartDescending),
  marketItem_en: algoliaClients.en.initIndex(config.algolia.marketItemEnIndexSaleStartDescending),
  marketItem_nl: algoliaClients.nl.initIndex(config.algolia.marketItemNlIndexSaleStartDescending),
  marketItem_pl: algoliaClients.pl.initIndex(config.algolia.marketItemPlIndexSaleStartDescending),
  marketItem_da: algoliaClients.da.initIndex(config.algolia.marketItemDaIndexSaleStartDescending),
  marketItem_fi: algoliaClients.fi.initIndex(config.algolia.marketItemFiIndexSaleStartDescending),
  marketItem_fr: algoliaClients.fr.initIndex(config.algolia.marketItemFrIndexSaleStartDescending),
  marketItem_cs: algoliaClients.cs.initIndex(config.algolia.marketItemCsIndexSaleStartDescending),
  marketItem_se_relevance: algoliaClients.sv.initIndex(config.algolia.marketItemSeIndexRelevance),
  marketItem_de_relevance: algoliaClients.de.initIndex(config.algolia.marketItemDeIndexRelevance),
  marketItem_en_relevance: algoliaClients.en.initIndex(config.algolia.marketItemEnIndexRelevance),
  marketItem_nl_relevance: algoliaClients.nl.initIndex(config.algolia.marketItemNlIndexRelevance),
  marketItem_pl_relevance: algoliaClients.pl.initIndex(config.algolia.marketItemPlIndexRelevance),
  marketItem_da_relevance: algoliaClients.da.initIndex(config.algolia.marketItemDaIndexRelevance),
  marketItem_fi_relevance: algoliaClients.fi.initIndex(config.algolia.marketItemFiIndexRelevance),
  marketItem_fr_relevance: algoliaClients.fr.initIndex(config.algolia.marketItemFrIndexRelevance),
  marketItem_cs_relevance: algoliaClients.cs.initIndex(config.algolia.marketItemCsIndexRelevance),
  model: algoliaClients.nonItem.initIndex(config.algolia.modelIndex)
}

export const algoliaSearch = (index, value, options) => {
  options = { hitsPerPage: 50, ...options }
  return algoliaIndexes[index].search(value, options).then((content) => content.hits)
}

export const algoliaCategorySearchWithSubCategories = async (category, level) => {
  const region = process.env.REGION
  return LOCALIZED_ITEM_RELEVANCE_INDEX.search('', {
    filters: searchStateTools.composeDefaultAlgoliaFilters({
      excludeP2p: true,
      firstAccessEligibility: true,
      region
    }),
    facets: [`categories.lvl${level}`, `categories.lvl${level + 1}`],
    facetFilters: level > 0 ? `categories.lvl${level - 1}:${category.value}` : undefined,
    hitsPerPage: 0
  }).then(({ facets }) => {
    const noSubCategories = Boolean(facets && Object.keys(facets).length === 0)
    if (noSubCategories) return null

    const allSubCategories = Object.entries(facets[`categories.lvl${level + 1}`] ?? {})

    const categories = Object.entries(facets[`categories.lvl${level}`])
      .filter(([itemValue]) => itemValue.startsWith(category?.value ?? ''))
      .map(([itemValue, itemCount]) => ({
        label: itemValue.split(' > ').pop(),
        value: itemValue,
        count: itemCount,
        subCategories: allSubCategories.filter(([subCategoryValue]) =>
          subCategoryValue.startsWith(itemValue)
        )
      }))
    return categories
  })
}

export const algoliaCategorySearch = async (category, level, facetFilters = []) => {
  const { value } = category
  const region = process.env.REGION
  return LOCALIZED_ITEM_RELEVANCE_INDEX.search('', {
    filters: searchStateTools.composeDefaultAlgoliaFilters({
      excludeP2p: true,
      firstAccessEligibility: true,
      region
    }),
    facets: [`categories.lvl${level}`],
    facetFilters: [[`categories.lvl${level - 1}:${value}`], ...facetFilters]
  }).then(({ facets }) => {
    const noSubCategories = Boolean(facets && Object.keys(facets).length === 0)
    if (noSubCategories) return null
    const subCategories = Object.entries(facets[`categories.lvl${level}`])
      .filter((item) => item[0].startsWith(value))
      .map((item) => ({
        label: item[0].split(' > ').pop(),
        value: item[0],
        count: item[1]
      }))
    return subCategories
  })
}

/** @type {SearchIndex} */
export const INIT_LOCALIZED_ITEM_INDEX = {
  sv: algoliaIndexes.marketItem_se,
  de: algoliaIndexes.marketItem_de,
  en: algoliaIndexes.marketItem_en,
  nl: algoliaIndexes.marketItem_nl,
  da: algoliaIndexes.marketItem_da,
  pl: algoliaIndexes.marketItem_pl,
  fi: algoliaIndexes.marketItem_fi,
  fr: algoliaIndexes.marketItem_fr,
  cs: algoliaIndexes.marketItem_cs
}[locale]

/** @type {SearchIndex} */
export const LOCALIZED_ITEM_RELEVANCE_INDEX = {
  sv: algoliaIndexes.marketItem_se_relevance,
  de: algoliaIndexes.marketItem_de_relevance,
  en: algoliaIndexes.marketItem_en_relevance,
  nl: algoliaIndexes.marketItem_nl_relevance,
  da: algoliaIndexes.marketItem_da_relevance,
  pl: algoliaIndexes.marketItem_pl_relevance,
  fi: algoliaIndexes.marketItem_fi_relevance,
  fr: algoliaIndexes.marketItem_fr_relevance,
  cs: algoliaIndexes.marketItem_cs_relevance
}[locale]

export const LOCALIZED_ITEM_INDEX_NAME = {
  sv: config.algolia.marketItemSeIndexRelevance,
  de: config.algolia.marketItemDeIndexRelevance,
  en: config.algolia.marketItemEnIndexRelevance,
  da: config.algolia.marketItemDaIndexRelevance,
  pl: config.algolia.marketItemPlIndexRelevance,
  nl: config.algolia.marketItemNlIndexRelevance,
  fr: config.algolia.marketItemFrIndexRelevance,
  fi: config.algolia.marketItemFiIndexRelevance,
  cs: config.algolia.marketItemCsIndexRelevance
}[locale]

export const AUTOCOMPLETE_INDEX = {
  sv: config.algolia.querySuggestionsSV,
  de: config.algolia.querySuggestionsDE,
  en: config.algolia.querySuggestionsEN,
  da: config.algolia.querySuggestionsDA,
  pl: config.algolia.querySuggestionsPL,
  fi: config.algolia.querySuggestionsFI,
  nl: config.algolia.querySuggestionsNL,
  fr: config.algolia.querySuggestionsFR,
  cs: config.algolia.querySuggestionsCS
}[locale]

export const STRUCTURED_SEARCH_INDEX = {
  sv: config.algolia.structuredSearchQuerySuggestionsSV,
  de: config.algolia.structuredSearchQuerySuggestionsDE,
  en: config.algolia.structuredSearchQuerySuggestionsEN,
  da: config.algolia.structuredSearchQuerySuggestionsDA,
  pl: config.algolia.structuredSearchQuerySuggestionsPL,
  fi: config.algolia.structuredSearchQuerySuggestionsFI,
  nl: config.algolia.structuredSearchQuerySuggestionsNL,
  fr: config.algolia.structuredSearchQuerySuggestionsFR,
  cs: config.algolia.structuredSearchQuerySuggestionsCS
}[locale]

export const BRAND_INDEX = 'prod_itemBrand'

/**
 * @deprecated not part of structured search
 */
export const AUTOCOMPLETE_INDEX_OLD = {
  sv: 'querySuggestions_sv',
  de: 'querySuggestions_de',
  en: 'querySuggestions_en',
  da: 'querySuggestions_da',
  pl: 'querySuggestions_pl',
  fi: 'querySuggestions_fi',
  nl: 'querySuggestions_nl',
  fr: 'querySuggestions_fr'
}[locale]

export const ALGOLIA_SORT_INDEX = {
  sv: {
    mostRecentlyAdded: config.algolia.marketItemSeIndexSaleStartDescending,
    relevance: config.algolia.marketItemSeIndexRelevance,
    cheapest: config.algolia.marketItemSeIndexPriceAscending,
    mostExpensive: config.algolia.marketItemSeIndexPriceDescending
  },
  de: {
    mostRecentlyAdded: config.algolia.marketItemDeIndexSaleStartDescending,
    relevance: config.algolia.marketItemDeIndexRelevance,
    cheapest: config.algolia.marketItemDeIndexPriceAscending,
    mostExpensive: config.algolia.marketItemDeIndexPriceDescending
  },
  en: {
    mostRecentlyAdded: config.algolia.marketItemEnIndexSaleStartDescending,
    relevance: config.algolia.marketItemEnIndexRelevance,
    cheapest: config.algolia.marketItemEnIndexPriceAscending,
    mostExpensive: config.algolia.marketItemEnIndexPriceDescending
  },
  nl: {
    mostRecentlyAdded: config.algolia.marketItemNlIndexSaleStartDescending,
    relevance: config.algolia.marketItemNlIndexRelevance,
    cheapest: config.algolia.marketItemNlIndexPriceAscending,
    mostExpensive: config.algolia.marketItemNlIndexPriceDescending
  },
  da: {
    mostRecentlyAdded: config.algolia.marketItemDaIndexSaleStartDescending,
    relevance: config.algolia.marketItemDaIndexRelevance,
    cheapest: config.algolia.marketItemDaIndexPriceAscending,
    mostExpensive: config.algolia.marketItemDaIndexPriceDescending
  },
  pl: {
    mostRecentlyAdded: config.algolia.marketItemPlIndexSaleStartDescending,
    relevance: config.algolia.marketItemPlIndexRelevance,
    cheapest: config.algolia.marketItemPlIndexPriceAscending,
    mostExpensive: config.algolia.marketItemPlIndexPriceDescending
  },
  fi: {
    mostRecentlyAdded: config.algolia.marketItemFiIndexSaleStartDescending,
    relevance: config.algolia.marketItemFiIndexRelevance,
    cheapest: config.algolia.marketItemFiIndexPriceAscending,
    mostExpensive: config.algolia.marketItemFiIndexPriceDescending
  },
  fr: {
    mostRecentlyAdded: config.algolia.marketItemFrIndexSaleStartDescending,
    relevance: config.algolia.marketItemFrIndexRelevance,
    cheapest: config.algolia.marketItemFrIndexPriceAscending,
    mostExpensive: config.algolia.marketItemFrIndexPriceDescending
  },
  cs: {
    mostRecentlyAdded: config.algolia.marketItemCsIndexSaleStartDescending,
    relevance: config.algolia.marketItemCsIndexRelevance,
    cheapest: config.algolia.marketItemCsIndexPriceAscending,
    mostExpensive: config.algolia.marketItemCsIndexPriceDescending
  }
}[locale]

export const ITEM_INDEX_FACETS = {
  brand: 'metadata.brand',
  type: 'metadata.type',
  color: 'metadata.color',
  material: 'metadata.material',
  fabric: 'metadata.fabric',
  pattern: 'metadata.pattern',
  condition: 'metadata.condition',
  price: `price_${region()}.amount`,
  salesChannel: 'salesChannel',
  demography: 'metadata.demography',
  sleeveLength: 'metadata.sleeveLength',
  heelHeight: 'metadata.measurement.heelHeightInCm',
  innerLegLength: 'metadata.measurement.innerLegLengthInCm',
  neckline: 'metadata.neckline',
  bag: 'bag',
  size: 'sizes',
  restrictedModel: 'metadata.restrictedModel',
  garmentLength: 'metadata.garmentLength',
  waistRise: 'metadata.waistRise',
  storageSite: 'storageSite',
  saleType: 'saleType',
  inHmResell_SE: 'inHmResell_SE',
  inHmResell_DE: 'inHmResell_DE'
}

export const useIsSearchLoading = () => {
  const {
    status,
    results: { queryID }
  } = useInstantSearch()

  const prevStatus = usePrevious(status)
  const firstRender = !prevStatus

  return !(status === 'idle' && queryID && !firstRender)
}
