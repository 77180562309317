fragment RejectInfoItemFragment on Item {
  objectId
  metadata
  metadata_de
  metadata_en
  metadata_nl
  metadata_da
  metadata_pl
  metadata_fi
  metadata_fr
  metadata_cs
  itemStatus
  container {
    unsellableReason
  }
  rejectReason
}

fragment RejectInfoSaleRequestFragment on SaleRequest {
  returnIfRejected
  motivation
  createdAt
}

fragment RejectInfoSendBackRequestFragment on SendBackRequest {
  createdAt
}
