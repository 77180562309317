fragment PDPLightBoxFragment on Item {
  objectId
  metadata 
  metadata_de
  metadata_en
  metadata_nl
  metadata_da
  metadata_pl
  metadata_fi
  metadata_fr
  metadata_cs
  photos {
    ... on Element {
      value
    }
  }
  itemStatus
}
