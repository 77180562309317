import last from 'lodash/last'
import memoize from 'lodash/memoize'
import { dynamicDescription, money } from '@sellpy/commons'
import { locale } from '../../common/region/config'
import { region } from '../region/region'

const _getCategoryNameFromIndex = (index) => `item_category${index !== 0 ? index + 1 : ''}`

const _analyticsItemMapper = (item) => {
  if (item.toJS) item = item.toJS()
  const price =
    item?.price ?? (item?.[`price_${region()}`] && money.toBaseUnit(item?.[`price_${region()}`]))
  const data = {
    item_id: item?.objectID,
    item_name: dynamicDescription.itemFullTitle({
      metadata: item.metadata,
      locale
    }),
    item_brand: item?.metadata?.brand,
    price: price?.amount,
    currency: price?.currency,
    affiliation: item?.user,
    item_variant: item?.metadata?.size,
    location_id: item?.storageSite,
    region: region(),

    // This snippet breaks last entry from the item.categories array into an array of values,
    // and then maps them to properties on the return object. Example:
    // This:
    // categories: {
    //   category1: ['Category1 > SubCategory1 > SubSubCategory1']
    // }
    // Becomes:
    // item_category: 'Category1',
    // item_category2: 'SubCategory1',
    // item_category3: 'SubSubCategory1',
    ...(item?.categories &&
      Object.fromEntries(
        last(last(Object.entries(item.categories))[1])
          .split(' > ')
          .map((category, index) => [_getCategoryNameFromIndex(index), category])
      )),
    item_category5: item?.metadata?.type,
    quantity: item?.quantity || 1,
    index: 0
  }
  return Object.fromEntries(Object.entries(data).filter(([_, value]) => value))
}

// Using a memoized mapper means that the result from the mapper gets cached,
// this makes retrieval of already mapped items effortless. Since we are using ImmutableJS
// the standard === comparison that lodash.memoize uses to determine if the result is already
// memoized doesn't work. We therefore need to provide a custom hash function that returns
// a unique value for each item. We use the hashCode function from ImmutableJS if it exists,
// otherwise we use the objectID from Algolia.
const memoizedAnalyticsItemMapper = memoize(_analyticsItemMapper, (item) =>
  item.hashCode ? item.hashCode() : item.objectID
)

export const analyticsItemMapper = (item) => {
  if (!item) return null
  return memoizedAnalyticsItemMapper(item)
}
